import { HTMLAttributes, ReactElement } from 'react';
import cn from 'classnames';
import s from './BlockAnchor.module.scss';
import { HubModuleCTA } from '../../../../types';
import { getCallToActionAttrs } from '../helpers';
import { Anchor } from '../../../shared/Anchor';

export interface BlockAnchorProps
  extends Pick<HTMLAttributes<HTMLAnchorElement>, 'className'> {
  link?: HubModuleCTA;
  children: (link?: ReturnType<typeof getCallToActionAttrs>) => ReactElement;
}

export default function BlockAnchor(props: BlockAnchorProps) {
  const { link, children, className } = props;

  if (link) {
    const attrs = getCallToActionAttrs(link);
    const { url, openInNewTab } = attrs;

    return (
      <Anchor
        to={url}
        openInNewTab={openInNewTab}
        className={cn(s.anchor, className)}
      >
        {children(attrs)}
      </Anchor>
    );
  }

  return children();
}
