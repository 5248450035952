import { ReactNode } from 'react';
import cn from 'classnames';
import s from './OfferCard.module.scss';
import { FeaturedColumnTextSection } from '../../types';
import { renderHTMLString } from '../../../../../../../../helpers';
import ImageTag from '../../../../../../../shared/ImageTag/ImageTag';
import AnimatedCallToAction from '../../../../../AnimatedCallToAction';

export interface OfferCardProps {
  section: FeaturedColumnTextSection;
  ctaLabel?: ReactNode;
  featured?: boolean;
}

export default function OfferCard(props: OfferCardProps) {
  const {
    section: { title, body, summary, image, alt, sponsorTitle, sponsorLogos },
    ctaLabel,
    featured,
  } = props;

  const wrapperClassName = cn(s.wrapper, {
    [s.regular]: !featured,
    [s.featured]: featured,
  });

  return (
    <div className={cn(wrapperClassName, 'animated-cta-trigger')}>
      <div className={s.body}>
        <div className={s.content}>
          <div className={s.topContent}>
            {title && <h4 className={s.title}>{title}</h4>}
            {body && <div className={s.text}>{renderHTMLString(body)}</div>}
          </div>
          <div className={s.bottomContent}>
            {sponsorLogos.length > 0 && (
              <div className={s.sponsorLogos}>
                <div className={s.sponsorLogosTitle}>
                  {sponsorTitle || 'Featuring'}:
                </div>
                <div className={s.logos}>
                  {sponsorLogos.map((logo, index) => (
                    <ImageTag
                      key={index}
                      src={logo.path}
                      className={s.logo}
                      testId="offer-card-logo"
                    />
                  ))}
                </div>
              </div>
            )}
            {ctaLabel && (
              <AnimatedCallToAction>{ctaLabel}</AnimatedCallToAction>
            )}
          </div>
        </div>
        {featured && (
          <div className={s.imageWrapper}>
            {image?.path ? (
              <ImageTag
                src={image.path}
                alt={alt}
                className={s.image}
                testId="offer-card-image"
              />
            ) : (
              <div className={s.fakeImage} />
            )}
          </div>
        )}
      </div>
      {summary && (
        <div className={s.footer}>
          <div className={s.summary}>{summary}</div>
        </div>
      )}
    </div>
  );
}
