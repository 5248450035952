import { PropsWithTestId } from '../../../../../../types';
import { FeaturedColumnTextModuleType } from './types';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import TwoFeaturedColumnsDisplayStyle from './TwoFeaturedColumnsDisplayStyle';

export interface FeaturedColumnTextModuleProps {
  module: FeaturedColumnTextModuleType;
}

export const defaultProps = {
  testId: 'hub-featured-column-text-module',
};

export default function FeaturedColumnTextModule(
  props: PropsWithTestId<FeaturedColumnTextModuleProps>,
) {
  const { module, testId } = props;
  const { '@type': type, elementId, visualStyle, displayStyle } = module;

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      testId={testId}
    >
      {displayStyle === 'TWO_FEATURED_COLUMNS' && (
        <TwoFeaturedColumnsDisplayStyle module={module} />
      )}
    </HubModuleWrapper>
  );
}

FeaturedColumnTextModule.defaultProps = defaultProps;
